@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

body {
  background-color: #101010;
  color: #ffffff;
}

h1 {
  color: #ffffff;
}

th, td {
  height: 2.75rem;
}

th {
  text-align: left;
}

td, th {
  background-color: #33333a;
  padding: .75rem;
}

table {
  border-collapse: separate;
  border-spacing: 0 .5rem;
}

tr td:first-child, tr th:first-child { 
  border-top-left-radius: .5rem; 
  border-bottom-left-radius: .5rem;
}

tr td:last-child, tr th:last-child { 
  border-top-right-radius: .5rem; 
  border-bottom-right-radius: .5rem; 
}

td {
  font-weight: 700;
}
